
import * as Vue from "vue";
import API from "@contrarian/ui-shared/lib/API";
import { ChevronDownIcon } from "@heroicons/vue/20/solid";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";

export default Vue.defineComponent({
  props: ['commodityId'],
  emits: ['selected'],
  components: {
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    ChevronDownIcon,
  },
  setup(props: any) {
    // Get Member's Inventory
    const activeInventories = Vue.ref<any[]>([]);
    (async () => {
      const records = await API.get('/inventory');
      activeInventories.value.push(...records);
    })();

    return {
      activeInventories,
      product: Vue.ref(),
    };
  },
  methods: {
    selectProduct(product: any) {
      this.product = product;
      this.$emit('selected', product);
    },
  },
});
