
import * as Vue from 'vue';
import { useStore } from "vuex";
import { Float, FloatArrow } from '@headlessui-float/vue'
import { Popover, PopoverPanel, PopoverButton } from '@headlessui/vue'
import { config } from "@/main";
import Data from "@/lib/Data";
import { useStream } from "@/lib/Stream";
import ContentWrapper from '@/layouts/ContentWrapper.vue';
import CommodityRecord from '@/components/CommodityRecord.vue';
import useEmitter from "@contrarian/ui-shared/composables/useEmitter";
import OverlayHowItWorksVideo from '@/components/OverlayHowItWorksVideo.vue';
import ProductOverlay from '@/components/ProductOverlay.vue';
import ICommoditySummary from "@/interfaces/ICommoditySummary";
import { millicents, addCommas, calculateMedian, calculateAverage } from "@contrarian/ui-shared/lib/Utils";
import WithTooltip from '@/components/WithTooltip.vue';

export default Vue.defineComponent({
  components: {
    ContentWrapper,
    CommodityRecord,
    OverlayHowItWorksVideo,
    Float,
    FloatArrow,
    Popover,
    PopoverPanel,
    PopoverButton,
    ProductOverlay,
    WithTooltip,
  },
  setup() {
    const stream = useStream();
    const store = useStore();
    const emitter = useEmitter();

    const commodities = Vue.reactive <ICommoditySummary[]> ([]);
    const marketSummariesById = Vue.reactive({});
    const dashboardStats = Vue.reactive<any>({});
    const memberId = Vue.computed(() => store.state.account.memberId);
    const member = Vue.reactive(store.state.account.member);
    const productsByGunTypeId = Vue.ref<any>({});

    const show = Vue.ref(false);
    const timer = Vue.ref(null) as Vue.Ref<ReturnType<typeof setTimeout> | null>;

    const open = () => {
      if (timer.value !== null) {
        clearTimeout(timer.value)
        timer.value = null
      }
      show.value = true
    }

    const close = () => {
      show.value = false
    }

    const delayClose = () => {
      timer.value = setTimeout(() => {
        show.value = false
      }, 150)
    }

    return {
      member,
      emitter,
      stream,
      commodities,
      marketSummariesById,
      memberId,
      config,
      dashboardStats,
      productsByGunTypeId,
      show,
      open,
      close,
      delayClose,
      posByProductId: {} as any,
    };
  },
  beforeMount() {
    // const { member } = this.store.state.account;
    // const isApproved = member.isApprovedTrader || member.isApprovedWholesaler;

    // if (!isApproved) {
    //   await this.router.push('/welcome');
    //   location.reload();
    // } else {
    //   location.reload();
    // }
    // emitter.emit('addAlert', { title: 'You have been logged in!' });
  },
  async mounted() {
    const [commodities, products] = await Promise.all([
      Data.fetchFeaturedCommodities(),
      Data.fetchProducts(),
    ]);

    const commodityIds: string[] = []
    for (const commodity of commodities) {
      commodityIds.push(commodity.id);
      this.commodities.push(commodity);
    }

    this.stream.activateMarketSummaries({
      marketType: 'commodity',
      ids: commodityIds
    }, this.marketSummariesById);

    this.stream.activateDashboard(this.dashboardStats);

    const productsByGunTypeId: any = {};
    const gunTypeIds: Set<string> = new Set();

    for (const product of products) {
      productsByGunTypeId[product.gunTypeId] ??= [];
      productsByGunTypeId[product.gunTypeId].push(product);
      gunTypeIds.add(product.gunTypeId);
    }
    for (const gunTypeId of gunTypeIds) {
      const width = 100 / productsByGunTypeId[gunTypeId].length
      productsByGunTypeId[gunTypeId].forEach((product: any, i: number) => {
        this.posByProductId[product.id] = { left: i * width, right: (i+1) * width };
      });
    }

    this.productsByGunTypeId = productsByGunTypeId;
  },

  beforeUnmount() {
    this.stream.deactivateMarketSummaries(this.commodities.map(x => x.id));
  },

  computed: {
    activities() {
      const { recentActivities } = (this as any).dashboardStats;
      if (!recentActivities) return [];

      let minSize = Infinity;
      let minPrice = 0;
      let maxPrice = 0;

      const sizes = recentActivities.map((x: any) => x.rounds);
      const medianSize = calculateMedian(sizes);
      const avgSize = calculateAverage(sizes);
      const maxSize = (medianSize + avgSize) / 2;

      for (const activity of recentActivities) {
        if (activity.price > maxPrice) maxPrice = activity.price;
        if (activity.price < minPrice) minPrice = activity.price;
        if (activity.rounds < maxPrice) minSize = activity.rounds;
      }

      return recentActivities.map((activity: any) => {
        const color = activity.price - activity.priceStart > 0 ? 'green' : 'red';
        const diff = (new Date(activity.createdAt).getMilliseconds() - 500) / 100;

        const sizeBase = Math.min(((activity.rounds - minSize) / avgSize) * 50, 50);
        const size = 80 + sizeBase;

        const bottomBase = ((activity.price - minPrice) / maxPrice) * 100;
        const leftBase = (this.posByProductId[activity.productId].left + this.posByProductId[activity.productId].right)/2;
        const bottom = Math.max(bottomBase + diff, 0);
        const left = Math.max(leftBase + diff, 0);

        return { ...activity, color, bottom, left, size };
      });
    }
  },

  methods: {
    millicents,
    addCommas,
    productClass(productId: string): string {
      const colors = {red: 'bg-marketRed', green: 'bg-marketGreen', gray: 'bg-marketGray' };
      if (!this.dashboardStats.productOverviewById) return colors.gray;

      const product = this.dashboardStats.productOverviewById[productId];
      if (!product) return colors.gray;

      const diff = product.sellPrice - product.sellPriceStart;
      if (diff > 0) {
        return colors.green;
      } else if (diff < 0) {
        return colors.red;
      }
      return colors.gray;
    },
    productClassHover(productId: string): string {
      return `${this.productClass(productId)}Hover`;
    },
    openRegister() {
      this.emitter.emit('openRegister');
    },
    openHowItWorksVideo() {
      this.emitter.emit('openHowItWorksVideo');
    }
  }
});
