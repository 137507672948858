
import * as Vue from 'vue';
import useEmitter from "@contrarian/ui-shared/composables/useEmitter";
import { useStore } from "vuex";
import { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot } from "@headlessui/vue";
import { InformationCircleIcon } from '@heroicons/vue/20/solid';
import { directive as VueInputAutowidth } from 'vue-input-autowidth';
import API from "@contrarian/ui-shared/lib/API";

export default Vue.defineComponent({
  props: ['data'],
  emits: ['close'],
  directives: {
    autowidth: VueInputAutowidth
  },
  components: {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    InformationCircleIcon,
  },
  async setup(props: any) {
    const emitter = useEmitter();
    const store = useStore();

    const commodityId = Vue.ref(props.data.commodityId);
    const productId = Vue.ref(props.data.productId);

    const inventory = await API.get('/warehouseInventory/' + (props.data.productId ? props.data.productId : props.data.commodityId));
    const boxSizes = Vue.ref<number[]>(inventory.map((x: any) => x.roundsPerBox));

    const minBoxSize = Vue.ref(boxSizes.value[0] ? boxSizes.value[0] : 20);
    const rounds = Vue.ref(minBoxSize.value);

    const price = props.data.price || 10;
    const milliCents = Vue.ref(0);
    const skipConfirmation = Vue.ref(false);
    const roundsBought = Vue.ref(0);

    milliCents.value = (price / 10);
    milliCents.value = Number(milliCents.value.toFixed(1))
    if (milliCents.value > 100) {
      milliCents.value = Number(milliCents.value.toFixed(0))
    }

    return {
      error: Vue.ref(''),
      emitter,
      store,
      milliCents,
      rounds,
      skipConfirmation,
      commodityId,
      productId,
      boxSizes,
      roundsBought,
      cashAvailable: Vue.computed(() => store.state.account.member.cashAvailable),
    };
  },
  methods: {
    openDepositOverlay() {
      this.emitter.emit("toggleDepositOverlay");
      this.$emit('close');
    },
    totalAmount() {
      const totalMillicents = Number(this.milliCents) * Number(this.rounds);
      const totalDollars = totalMillicents / 100;
      return totalDollars.toFixed(2);
    },
    async submitOrder() {
      if (this.rounds <= 0) return;
      const data = {
        price: Math.round(this.milliCents * 10),
        rounds: Number(this.rounds),
        productId: this.productId,
        commodityId: this.commodityId,
      };
      try {
        await this.store.dispatch('account/SubmitBuyOrder', data);
        this.emitter.emit('addAlert', {
          id: `${data.productId || data.commodityId}-${data.price}-${data.rounds}`,
          title: 'Offer Submitted',
          extra: 'You will be notified when processing is complete.',
        });
        this.roundsBought = Number(this.rounds);
        if (this.skipConfirmation) {
          this.closeOverlay();
        }
      } catch (error) {
        this.error = (error as Error).message;
      }
    },
    closeOverlay() {
      this.$emit('close');
    },
    incrCent() {
      this.milliCents = Number(this.milliCents) + 1;
    },
    decrCent() {
      this.milliCents = Math.max(1, Number(this.milliCents) - 1);
    },
    incrBox() {
      let rounds = this.rounds;
      while (true) {
        rounds++;
        if (this.isBoxSize(rounds)) break;
      }
      this.rounds = rounds;
    },
    decrBox() {
      let rounds = this.rounds;
      while (true) {
        rounds--;
        if (rounds <= 0) {
          rounds = 0;
          break;
        }
        if (this.isBoxSize(rounds)) break;
      }
      if(rounds > 0) this.rounds = rounds;
    },
    isBoxSize(rounds: number): boolean {
      if (!this.boxSizes.length) return true;

      for (const boxSize of this.boxSizes) {
        if (rounds % boxSize == 0) {
          return true;
        }
      }
      return false;
    }
  }
});
