
import * as Vue from "vue";
import useEmitter from "@contrarian/ui-shared/composables/useEmitter";
import {
  useStore
} from "vuex";
import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItems
} from "@headlessui/vue";
import { ChevronDownIcon } from "@heroicons/vue/20/solid";
import API from "@contrarian/ui-shared/lib/API";
import CreditCardForm from './CreditCardForm.vue'
import {
  CreditCardIcon
} from "@heroicons/vue/20/solid";
import {
  Dialog,
  DialogOverlay,
  DialogTitle,
  TransitionChild,
  TransitionRoot
} from '@headlessui/vue'
import {addCommas} from "../../lib/Utils";

export default Vue.defineComponent({
  props: ["maxDeposit", "defaultDeposit", "inviteId"],
  components: {
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    ChevronDownIcon,
    CreditCardForm,
    CreditCardIcon,
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot
  },
  setup(props) {
    const emitter = useEmitter();
    const store = useStore();

    let cardFormIsOpen = Vue.ref(false)

    const AmountStatus = Vue.ref({
      message: `Max ${addCommas(props.maxDeposit)}`,
      color: "gray",
    });
    const AccountStatus = Vue.ref({
      message: "Loading",
      color: "gray",
    });
    const FormStatus = Vue.ref({
      message: "",
      color: "gray",
    });

    let amount = Vue.ref(props.defaultDeposit || 100);
    let cvcInput = Vue.ref();
    let account = Vue.ref();

    let memberId = Vue.ref('')
    if (props.inviteId) {
      API.get('/invites/getMemberByInviteId/' + props.inviteId).then(async (response) => {
        memberId.value = response.memberId
        let paymentMethodsPromise = API.post("/getPaymentMethods", {
          paymentMethodType: "card",
          memberId: response.memberId
        });
        let bankAccountsPromise = API.post("/getPaymentMethods", {
          paymentMethodType: "bank",
          memberId: response.memberId
        });
        Promise.all([paymentMethodsPromise, bankAccountsPromise])
          .then((values) => {
            if (values[0].length > 0 || values[1].length > 0) {
              AccountStatus.value = {
                message: "No Sources Found",
                color: "gray",
              };

              paymentMethods.value = values[0];
              bankAccounts.value = values[1];
              AccountStatus.value = {
                message: "",
                color: "",
              };
            } else if (values[0].length == 0 && values[1].length == 0) {
              AccountStatus.value = {
                message: "No Sources Found",
                color: "gray",
              };
            } else {
              AccountStatus.value = {
                message: "Server Error",
                color: "red",
              };
            }
          })
          .catch((error) => {
            AccountStatus.value = {
              message: "Server Error",
              color: "red",
            };
          });
      })
    } else {
      let paymentMethodsPromise = API.post("/getPaymentMethods", {
        paymentMethodType: "card",
      });
      let bankAccountsPromise = API.post("/getPaymentMethods", {
        paymentMethodType: "bank",
      });
      Promise.all([paymentMethodsPromise, bankAccountsPromise])
        .then((values) => {
          if (values[0].length > 0 || values[1].length > 0) {
            AccountStatus.value = {
              message: "No Sources Found",
              color: "gray",
            };

            paymentMethods.value = values[0];
            bankAccounts.value = values[1];
            AccountStatus.value = {
              message: "",
              color: "",
            };
          } else if (values[0].length == 0 && values[1].length == 0) {
            AccountStatus.value = {
              message: "No Sources Found",
              color: "gray",
            };
          } else {
            AccountStatus.value = {
              message: "Server Error",
              color: "red",
            };
          }
        })
        .catch((error) => {
          AccountStatus.value = {
            message: "Server Error",
            color: "red",
          };
        });

    }

    const paymentMethods = Vue.ref([]);
    const bankAccounts = Vue.ref([]);

    const activeAccount = Vue.ref();
    const loading = Vue.ref(false);

    emitter.on("updateCashAvailable", async () => {
      cardFormIsOpen.value = false;

      if (props.inviteId) {
        API.get('/invites/getMemberByInviteId/' + props.inviteId).then(async (response) => {
          memberId.value = response.memberId
          let paymentMethodsPromise = API.post("/getPaymentMethods", {
            paymentMethodType: "card",
            memberId: response.memberId
          });
          let bankAccountsPromise = API.post("/getPaymentMethods", {
            paymentMethodType: "bank",
            memberId: response.memberId
          });
          Promise.all([paymentMethodsPromise, bankAccountsPromise])
            .then((values) => {
              if (values[0].length > 0 || values[1].length > 0) {
                AccountStatus.value = {
                  message: "No Sources Found",
                  color: "gray",
                };

                paymentMethods.value = values[0];
                bankAccounts.value = values[1];
                AccountStatus.value = {
                  message: "",
                  color: "",
                };
              } else if (values[0].length == 0 && values[1].length == 0) {
                AccountStatus.value = {
                  message: "No Sources Found",
                  color: "gray",
                };
              } else {
                AccountStatus.value = {
                  message: "Server Error",
                  color: "red",
                };
              }
            })
            .catch((error) => {
              AccountStatus.value = {
                message: "Server Error",
                color: "red",
              };
            });
        })
      } else {
        let paymentMethodsPromise = API.post("/getPaymentMethods", {
          paymentMethodType: "card",
        });
        let bankAccountsPromise = API.post("/getPaymentMethods", {
          paymentMethodType: "bank",
        });
        Promise.all([paymentMethodsPromise, bankAccountsPromise])
          .then((values) => {
            if (values[0].length > 0 || values[1].length > 0) {
              AccountStatus.value = {
                message: "No Sources Found",
                color: "gray",
              };

              paymentMethods.value = values[0];
              bankAccounts.value = values[1];
              AccountStatus.value = {
                message: "",
                color: "",
              };
            } else if (values[0].length == 0 && values[1].length == 0) {
              AccountStatus.value = {
                message: "No Sources Found",
                color: "gray",
              };
            } else {
              AccountStatus.value = {
                message: "Server Error",
                color: "red",
              };
            }
          })
          .catch((error) => {
            AccountStatus.value = {
              message: "Server Error",
              color: "red",
            };
          });

      }

    });

    (() => {

    })();

    return {
      emitter,
      store,
      cardFormIsOpen,
      AmountStatus,
      AccountStatus,
      amount,
      account,
      paymentMethods,
      bankAccounts,
      activeAccount,
      loading,
      FormStatus,
      memberId,
      cvcInput
    };
  },
  mounted() {},
  beforeUnmount() {},
  methods: {
    addNewCard() {
      this.cardFormIsOpen = true
    },
    validateAmount() {
      if (this.amount > this.maxDeposit) {
        this.amount = this.maxDeposit;
        this.AmountStatus = {
          message: `Max ${this.maxDeposit}`,
          color: "red",
        };
      }
    },
    selectAccount(paymentMethod: any) {
      this.activeAccount = paymentMethod;
    },
    clearForm() {
      this.amount = "";
      this.activeAccount = null;
      this.loading = false;
      this.FormStatus = {
        message: "",
        color: "",
      };
    },
    pay() {
      this.loading = true;
      let data: any = {
        amount: this.amount * 100000,
      };
      if (this.memberId) {
        data.memberId = this.memberId
      }
      if (
        this.activeAccount &&
        this.amount >= 1 &&
        this.amount <= this.maxDeposit
      ) {
        data.cvc = this.cvcInput
        data.tokenizedCard = this.activeAccount.token
        API.post('/fortis/initiateCardPayment', data).then(response => {
          if (response.status == "successful") {
            this.emitter.emit("addAlert", {
              title: "Deposit Requested",
              extra: "Deposit will appear in account shortly",
            });
            this.emitter.emit("depositCompleted");
            this.emitter.emit("updateCashAvailable");
            this.clearForm();
            this.FormStatus = {
              message: "Deposit Success",
              color: "gray",
            };
          } else if (response.error) {
            this.clearForm();
            this.FormStatus = {
              message: response.message,
              color: "red",
            };

          } else {

          }
        })
      } else {
        this.loading = false;
        this.FormStatus = {
          message: "Form Not Complete",
          color: "red",
        };
      }
    },
  },
});
