
import * as Vue from 'vue';
import { useStore } from 'vuex';
import useEmitter from '@contrarian/ui-shared/composables/useEmitter';
import API from '@contrarian/ui-shared/lib/API';

export default Vue.defineComponent({
  props: ['alignTo', 'commodityId', 'productId', 'price'],
  components: {},
  setup(props: any) {
    const emitter = useEmitter();
    const store = useStore();

    const memberId = Vue.computed(() => store.state.account.memberId);
    const isOpen = Vue.ref(false);

    const tradingEngineStatus = Vue.ref({})
    API.get('/engine/status').then(response => tradingEngineStatus.value = response);

    return {
      emitter,
      isOpen,
      memberId,
      tradingEngineStatus
    };
  },
  methods: {
    buttonClick(event: any) {
      if (!this.memberId) return;

      event.stopPropagation();
      event.preventDefault();
      const triggerElement = event.target;
      const data = {
        commodityId: this.commodityId,
        productId: this.productId,
        price: this.price,
      };
      const alignTo = this.prepareAlignTo(this.alignTo);
      this.emitter.emit('openBuyPanel', {
        data,
        triggerElement,
        alignTo
      });
    },
    prepareAlignTo(alignTo: any) {
      const {
        rightOf,
        leftOf,
        centerOf
      } = alignTo;
      if (rightOf === 'parent') {
        return {
          rightOf: this.$el.parentElement
        };
      } else if (leftOf === 'parent') {
        return {
          leftOf: this.$el.parentElement
        };
      } else if (centerOf === 'parent') {
        return {
          centerOf: this.$el.parentElement
        };
      }
      return alignTo;
    }
  }
});
