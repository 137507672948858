
import * as Vue from "vue";
import { useStore } from "vuex";
import Deposit from '@contrarian/ui-shared/components/paymentCollection/Deposit.vue'
import useEmitter from "@contrarian/ui-shared/composables/useEmitter";
import {
  Dialog,
  DialogOverlay,
  DialogTitle,
  TransitionChild,
  TransitionRoot
} from '@headlessui/vue'

export default Vue.defineComponent({
  components: {
    Dialog,
    Deposit,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot
  },
  setup() {
    const store = useStore();
    const emitter = useEmitter();

    const errorMessage = Vue.ref();
    const overlayOpen = Vue.ref(false);
    const loading = Vue.ref(false)

    emitter.on("toggleDepositOverlay", async () => {
      loading.value = false
      errorMessage.value = null
      overlayOpen.value = !overlayOpen.value
    })

    return {
      store,
      emitter,
      overlayOpen,
      errorMessage,
      loading,
    };
  },
  methods: {
    toggleOverlay() {
      this.emitter.emit("toggleDepositOverlay");

    },
  },
});
