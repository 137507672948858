
import * as Vue from "vue";
import useEmitter from "@contrarian/ui-shared/composables/useEmitter";
import { useStore } from "vuex";
import { PlusSmallIcon } from "@heroicons/vue/20/solid";
import { MinusSmallIcon } from "@heroicons/vue/20/solid";
import API from "@contrarian/ui-shared/lib/API";
import { directive as VueInputAutowidth } from 'vue-input-autowidth';
import ProductMenu from "./ProductMenu.vue";
import ShipmentIcon from "@/layouts/nav/ShipmentIcon.vue";

export default Vue.defineComponent({
  props: {
    data: Object,
  },
  emits: ["close"],
  components: {
    PlusSmallIcon,
    MinusSmallIcon,
    ProductMenu,
    ShipmentIcon,
  },
  directives: {
    autowidth: VueInputAutowidth
  },
  async setup(props: any) {
    const modalOpen = true;
    const emitter = useEmitter();
    const store = useStore();

    const isLoading = Vue.ref(true);
    const commodityId = Vue.ref(props.data.commodityId);
    const productId = Vue.ref(props.data.productId);
    const data = Vue.ref(props.data);

    const boxSizes = Vue.ref<number[]>([]);
    const minBoxSize = Vue.ref(0);
    const rounds = Vue.ref(0);
    const skipConfirmation = Vue.ref(false);
    const hasEnoughRounds = Vue.ref(true);

    return {
      isLoading,
      modalOpen,
      emitter,
      store,
      rounds,
      commodityId,
      productId,
      data,
      boxSizes,
      minBoxSize,
      skipConfirmation,
      hasEnoughRounds,
      roundsAddedToCart: Vue.ref(0),
      selectedProduct: Vue.ref(),
    };
  },
  async beforeMount() {
    if (this.data.productId) {
      await this.calculateBoxSizes(this.data.productId);
      this.isLoading = false;
    }
  },
  methods: {
    async calculateBoxSizes(productId: string) {
      const inventory = await API.get(`/warehouseInventory/${productId}`);
      const boxSizes = inventory.map((x: any) => x.roundsPerBox);
      this.boxSizes = boxSizes.sort((a: number, b: number) => a - b);
      this.minBoxSize = boxSizes[0] ? boxSizes[0] : 20;
      this.rounds = Math.min(this.minBoxSize, this.getRoundsOwned());
      if (this.rounds < this.minBoxSize) {
        this.hasEnoughRounds = false;
      }
    },
    getRoundsOwned() {
      return this.selectedProduct?.rounds || this.data.roundsOwned;
    },
    async onProductSelected(product: any) {
      this.isLoading = true;
      await this.calculateBoxSizes(product.id);
      this.selectedProduct = product;
      this.ensureRoundsRange();
      this.isLoading = false;
    },
    addItemToCart() {
      const rounds = Number(this.rounds);
      this.store.commit('account/addShippingItem', {
        productId: this.data.productId || this.selectedProduct.productId,
        brandId: this.data.brandId || this.selectedProduct.brandId,
        rounds: rounds,
        roundsOwned: this.getRoundsOwned(),
        boxSizes: this.boxSizes,
      });
      this.roundsAddedToCart = rounds;
      if (this.skipConfirmation) {
        this.closeOverlay();
      }
    },
    closeOverlay() {
      this.$emit('close');
    },
    openShippingCart() {
      this.closeOverlay();
      this.$router.push('/account/shipping-cart');
    },
    incrBox() {
      let rounds = this.rounds;
      while (true) {
        rounds++;
        const roundsOwned = this.getRoundsOwned();
        if (roundsOwned && rounds > roundsOwned) {
          rounds = roundsOwned;
          break;
        }
        if (this.isBoxSize(rounds)) break;
      }
      this.rounds = rounds;
    },
    decrBox() {
      let rounds = this.rounds;
      while (true) {
        rounds--;
        if (rounds <= 0) {
          rounds = 0;
          break;
        }
        if (this.isBoxSize(rounds)) break;
      }
      if(rounds > 0) this.rounds = rounds;
    },
    onInputBlur() {
      this.ensureRoundsRange();
    },
    isBoxSize(rounds: number): boolean {
      if (!this.boxSizes.length) return true;

      for (const boxSize of this.boxSizes) {
        if (rounds % boxSize == 0) {
          return true;
        }
      }
      return false;
    },
    ensureRoundsRange() {
      const roundsOwned = this.getRoundsOwned();
      if (this.rounds > roundsOwned) this.rounds = roundsOwned;
      if (this.rounds < this.minBoxSize) this.rounds = Math.min(roundsOwned, this.minBoxSize);
    }
  },
});
