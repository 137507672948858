import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4cfce5ef"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "relative" }
const _hoisted_2 = {
  key: 1,
  disabled: "",
  class: "bg-slate-100 inline-block w-full text-center py-1.5 border border-gray-300 shadow-sm text-xs rounded text-gray-700 bg-white hover:border-gray-400 focus:outline-none"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InlineSvg = _resolveComponent("InlineSvg")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.roundsOwned > 0)
      ? (_openBlock(), _createElementBlock("button", {
          key: 0,
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.buttonClick && _ctx.buttonClick(...args))),
          class: _normalizeClass([_ctx.isOpen ? '' : 'text-opacity-90', "inline-block w-full text-center py-1.5 border border-gray-300 shadow-sm text-xs rounded text-gray-700 bg-white hover:border-gray-400 focus:outline-none"])
        }, [
          _createVNode(_component_InlineSvg, {
            src: require(`@/assets/icons/ship.svg`),
            class: "inline stroke-black relative -top-[1px]",
            height: "10"
          }, null, 8, ["src"]),
          _createTextVNode(" Ship ")
        ], 2))
      : (_openBlock(), _createElementBlock("button", _hoisted_2, [
          _createVNode(_component_InlineSvg, {
            src: require(`@/assets/icons/ship.svg`),
            class: "inline stroke-black relative -top-[1px] mr-1",
            height: "14"
          }, null, 8, ["src"]),
          _createTextVNode(" Ship ")
        ]))
  ]))
}