
import * as Vue from "vue";
import useEmitter from "@contrarian/ui-shared/composables/useEmitter";
import {
  useStore
} from "vuex";
import API from "@contrarian/ui-shared/lib/API";

export default Vue.defineComponent({
  props: ['alignTo', 'commodityId', 'productId', 'roundsOwned', 'price'],
  components: {},
  setup() {
    const emitter = useEmitter();
    const store = useStore();
    const tradingEngineStatus = Vue.ref({})
    const memberId = Vue.computed(() => store.state.account.memberId);
    const isOpen = Vue.ref(false);
    API.get('/engine/status').then(response => tradingEngineStatus.value = response);

    return {
      emitter,
      isOpen,
      memberId,
      tradingEngineStatus,
    };
  },
  methods: {
    buttonClick(event: any) {
      if (!this.memberId) return;

      event.stopPropagation();
      event.preventDefault();
      const triggerElement = event.target;
      const {
        commodityId,
        productId,
        price,
        roundsOwned,
      } = this;
      const alignTo = this.prepareAlignTo(this.alignTo);
      const data = {
        roundsOwned,
        commodityId,
        productId,
        price
      };
      this.emitter.emit('openSellPanel', {
        data,
        triggerElement,
        alignTo
      });
    },
    prepareAlignTo(alignTo: any) {
      const {
        rightOf,
        leftOf,
        centerOf
      } = alignTo;
      if (rightOf === 'parent') {
        return {
          rightOf: this.$el.parentElement
        };
      } else if (leftOf === 'parent') {
        return {
          leftOf: this.$el.parentElement
        };
      } else if (centerOf === 'parent') {
        return {
          centerOf: this.$el.parentElement
        };
      }
      return alignTo;
    }
  }
});
