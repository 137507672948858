import { useStore, Store } from 'vuex';
import { Socket } from "socket.io-client";
import moment from 'moment'
import StreamBase, { createSocket } from '@contrarian/ui-shared/lib/Stream';
import IMarketSummary, {IMarketType} from "@/interfaces/IMarketSummary";
import IInventoryHolding from "@/interfaces/IInventoryHolding";
import { emitter } from "@/main";

// moment.relativeTimeThreshold('ss', 0);

interface IMarketSummariesById {
  [id: string]: IMarketSummary;
}

let socket: Socket;

export function useStream() {
  const store = useStore();

  if (!socket) {
    const sessionToken = store.state.session.sessionToken;
    socket = createSocket(sessionToken);

    socket.on('Authed', auth => {
      store.commit('session/setSessionToken', auth.sessionToken);
      if (auth.member) store.commit('account/updateMember', auth.member);
    });

    socket.on('Event', ({ eventType, payload }) => {
      if (eventType === 'PriceChange') {
        const { productId, commodityId, buyPrice, sellPrice } = payload;
        const eventData = { productId, commodityId, buyPrice, sellPrice };
        const marketId = productId || commodityId;
        const event = new PriceChangeEvent(`PriceChange:${marketId}`, eventData);
        target.dispatchEvent(event);
      } else if (eventType === 'OrderBookChange') {
        const { productId, commodityId, type, price, rounds, roundsAdded, roundsRemoved } = payload;
        const eventData = { type, price, rounds, roundsAdded, roundsRemoved };
        const event = new OrderBookChangeEvent(`OrderBookChange:${commodityId}`, eventData);
        target.dispatchEvent(event);
        if (productId) {
          const event = new OrderBookChangeEvent(`OrderBookChange:${productId}`, eventData);
          target.dispatchEvent(event);
        }
      } else if (eventType === 'LastOrderBought') {
        const marketId = payload.productId || payload.commodityId;
        const { productId, commodityId, price, rounds, createdAt } = payload;
        const eventData = { productId, commodityId, price, rounds, createdAt };
        const event = new LastOrderBoughtEvent(`LastOrderBought:${marketId}`, eventData);
        target.dispatchEvent(event);
      } else if (eventType === 'BuyOrderSubmitted') {
        emitter.emit('addAlert', {
          id: `${payload.productId || payload.commodityId}-${payload.price}-${payload.rounds}`,
          title: 'Offer Recorded',
          extra: `${payload.rounds} rounds of ${payload.productId || payload.commodityId} at ${payload.price / 10}₵`,
        });
      } else if (eventType === 'BuyOrderSettled') {
        const pendingRoundsToBuy = store.state.account.member.pendingRoundsToBuy - payload.rounds;
        const roundsOwned = store.state.account.member.roundsOwned += payload.rounds;
        const cashAvailable = store.state.account.member.cashAvailable -= (payload.price * payload.rounds)
        store.commit('account/updateMember', { pendingRoundsToBuy, roundsOwned, cashAvailable });

        emitter.emit(`buyOrderSettled:${payload.commodityId}`, payload);
        emitter.emit(`buyOrderSettled:${payload.productId}`, payload);
        emitter.emit('addAlert', {
          ids: [
            `${payload.productId}-${payload.buyOrderPrice}-${payload.rounds}`,
            `${payload.commodityId}-${payload.buyOrderPrice}-${payload.rounds}`
          ],
          title: 'Trade Executed',
          extra: `${payload.rounds} rounds of ${payload.productId} bought at ${payload.price / 10}₵`,
        });
      } else if (eventType === 'SellOrderSettled') {
        const pendingRoundsToSell = store.state.account.member.pendingRoundsToSell - payload.rounds;
        const roundsOwned = store.state.account.member.roundsOwned -= payload.rounds;
        const cashAvailable = store.state.account.member.cashAvailable += (payload.price * payload.rounds)
        store.commit('account/updateMember', { pendingRoundsToSell, roundsOwned, cashAvailable });

        emitter.emit(`sellOrderSettled:${payload.commodityId}`, payload);
        emitter.emit(`sellOrderSettled:${payload.productId}`, payload);
        emitter.emit('addAlert', {
          id: `${payload.productId}-${payload.sellOrderPrice}-${payload.rounds}`,
          title: 'Trade Executed',
          extra: `${payload.rounds} rounds of ${payload.productId} sold at ${payload.price / 10}₵`,
        });
      }
    });
  }

  return new Stream(socket, store);
}

const target = new EventTarget();

export class Stream extends StreamBase {
  private store: Store<any>;

  constructor(socket: Socket, store: Store<any>) {
    super(socket);
    this.store = store;
  }

  public listenForApprovedAmount(activeInviteId: string, callback: any) {
    this.socket.on(`ApprovedAmount`, ({ inviteId, approvedAmount }) => {
      if (inviteId == activeInviteId) {
        callback({ inviteId, approvedAmount });
      }
    });
  }

  public search({ marketType, caliberId, gradeId }: { marketType: IMarketType, caliberId: string, gradeId: string }, summariesById: IMarketSummariesById): Promise<void> {
    const certifiesIn = this.store.state.account.certifiesIn;
    return new Promise<void>(async (resolve, reject) => {
      this.socket.emit('search', { marketType, caliberId, gradeId, certifiesIn }, (records: any[]) => {
        this.updateMarketSummaries(marketType, certifiesIn, records, summariesById);
        resolve();
      });
    });
  }

  public activateMarketSummaries({ marketType, ids }: { marketType: IMarketType, ids: string[] }, summariesById: IMarketSummariesById) {
    for (const id of ids) {
      summariesById[id] = {
        marketType,
        buyPrice: 0,
        buyPriceStart: 0,
        sellPrice: Infinity,
        sellPriceStart: Infinity,
        lastOrderBought: {
          price: null,
          rounds: null,
          createdAt: null,
        },
        isActive: false,
      };
    }
    const certifiesIn = this.store.state.account.certifiesIn;
    this.socket.emit('activateMarketSummaries', marketType, ids, certifiesIn, (records: any[]) => {
      this.updateMarketSummaries(marketType, certifiesIn, records, summariesById);
    });
  }

  public deactivateMarketSummaries(ids: string[]) {
    this.socket.emit('deactivateMarketSummaries', ids);
  }

  public activateDashboard(dashboard: any) {
    dashboard.currentValue = '---.--';
    dashboard.todayChange = '--';
    dashboard.alltimeChange = '--';
    dashboard.recentActivities = [];
    dashboard.productOverviewById = [];

    this.socket.emit('activateDashboard', (record: any) => {
      dashboard.currentValue = record.currentValue;
      dashboard.todayChange = record.todayChange;
      dashboard.alltimeChange = record.alltimeChange;
      dashboard.recentActivities = record.recentActivities;
      dashboard.productOverviewById = record.productOverviewById;
    });
  }

  public activateMarketPage({ marketType, id, memberId }: { marketType: IMarketType, id: string, memberId: string }, marketPage: any) {
    marketPage.id = id
    marketPage.marketType = marketType;
    marketPage.buyPrice = '--.-';
    marketPage.buyPriceStart = '--';
    marketPage.sellPrice = '--.-';
    marketPage.sellPriceStart = '--';
    marketPage.orderBook = { buy: [], sell: [] };
    marketPage.memberBuyOrdersByPrice = null;
    marketPage.memberSellOrdersByPrice = null;
    marketPage.activeInventory = null;
    marketPage.stats = {
      tradesToday: '--',
      tradesTotal: '--',
      tradesLastThirty: '--'
    };

    const certifiesIn = this.store.state.account.certifiesIn;
    this.socket.emit('activateMarketPage', { marketType, id, memberId, certifiesIn }, (record: any) => {
      const orderBook = extractOrderBook(record.orderBook, marketPage.orderBook);
      marketPage.buyPrice = record.buyPrice;
      marketPage.buyPriceStart = record.buyPriceStart;
      marketPage.sellPrice = record.sellPrice;
      marketPage.sellPriceStart = record.sellPriceStart;
      marketPage.orderBook = orderBook as IOrderBook;
      marketPage.memberBuyOrderCount = record.memberBuyOrderCount;
      marketPage.memberSellOrderCount = record.memberSellOrderCount;
      marketPage.memberBuyOrdersByPrice = record.memberBuyOrdersByPrice;
      marketPage.memberSellOrdersByPrice = record.memberSellOrdersByPrice;
      marketPage.activeInventory = record.activeInventory;
      marketPage.stats = Object.assign(marketPage.stats, record.stats);

      emitter.on(`buyOrderSubmitted:${id}`, (data: any) => marketPage.memberBuyOrderCount += data.rounds);
      emitter.on(`sellOrderSubmitted:${id}`, (data: any) => marketPage.memberSellOrderCount += data.rounds);
      emitter.on(`buyOrderSettled:${id}`, (data: any) => {
        marketPage.memberBuyOrderCount -= data.rounds;
        marketPage.activeInventory.rounds += data.rounds;
      });
      emitter.on(`sellOrderSettled:${id}`, (data: any) => {
        marketPage.memberSellOrderCount -= data.rounds;
        marketPage.activeInventory.rounds -= data.rounds;
      });

      target.addEventListener(`PriceChange:${id}`, change => {
        const { buyPrice, sellPrice, buyPriceStart, sellPriceStart } = change as PriceChangeEvent;
        marketPage.buyPrice = buyPrice ?? marketPage.buyPrice;
        marketPage.buyPriceStart = buyPriceStart ?? marketPage.buyPriceStart;
        marketPage.sellPrice = sellPrice ?? marketPage.sellPrice;
        marketPage.sellPriceStart = sellPriceStart ?? marketPage.sellPriceStart;
      });
      target.addEventListener(`OrderBookChange:${id}`, change => {
        const { orderType, price, rounds, roundsAdded, roundsRemoved } = change as OrderBookChangeEvent;
        const listKey = orderType == 1 ? 'buy' : 'sell';
        const list = orderBook[listKey] as IOrderList;

        let index = list.findIndex(x => x.price === price);

        if (index < 0 && !roundsRemoved) {
          list.push({ price, rounds: rounds || roundsAdded });
          sortInPlaceByPrice(list, orderType === 1);
        } else if (index >= 0) {
          const item = list[index];
          if (rounds) {
            item.rounds = rounds;
          } else if (roundsRemoved) {
            item.rounds -= roundsRemoved;
          } else if (roundsAdded) {
            item.rounds += roundsAdded;
          }
        }

        index = list.findIndex(x => x.price === price);
        if (index >= 0 && list[index].rounds === 0) {
          list.splice(index, 1);
        }
      });
    });
  }

  public deactivateMarketPage(id: string) {
    this.socket.emit('deactivateMarketPage', id);
  }

  private updateMarketSummaries(marketType: string, certifiesIn: number, records: any[], summariesById: IMarketSummariesById) {
    for (const record of records) {
      const id = marketType === 'commodity' ? record.commodityId : record.productId;

      summariesById[id] ??= {
        marketType,
        buyPrice: 0,
        buyPriceStart: 0,
        sellPrice: Infinity,
        sellPriceStart: Infinity,
        activeInventory: undefined,
        lastOrderBought: {
          price: null,
          rounds: null,
          createdAt: null,
        },
        isActive: false,
      };

      const summary = summariesById[id];

      summary.buyPrice = record.buyPrice;
      summary.buyPriceStart = record.buyPriceStart;
      summary.sellPrice = record.sellPrice;
      summary.sellPriceStart = record.sellPriceStart;
      summary.activeInventory = record.activeInventory;
      summary.lastOrderBought = Object.assign(
        summary.lastOrderBought as any,
        record.lastOrderBought,
        { createdAt: moment.utc(record.lastOrderBought.createdAt) },
      );
      summary.isActive = true;

      target.addEventListener(`PriceChange:${id}`, change => {
        const { buyPrice, sellPrice, buyPriceStart, sellPriceStart } = change as PriceChangeEvent;
        summary.buyPrice = buyPrice ?? summary.buyPrice;
        summary.buyPriceStart = buyPriceStart ?? summary.buyPriceStart;
        summary.sellPrice = sellPrice ?? summary.sellPrice;
        summary.sellPriceStart = sellPriceStart ?? summary.sellPriceStart;
      });

      target.addEventListener(`LastOrderBought:${id}`, lastOrderBought => {
        const { createdAt } = lastOrderBought as LastOrderBoughtEvent;
        summary.lastOrderBought = Object.assign(
          summary.lastOrderBought as any,
          lastOrderBought,
          { createdAt: moment.utc(createdAt) },
        );
      });
    }
  }
}

// RELATED CLASSES /////////////////////////////////

class PriceChangeEvent extends Event {
  public buyPrice: number;
  public sellPrice: number;
  public buyPriceStart: number;
  public sellPriceStart: number;

  constructor(type: string, data: any) {
    super(type);
    this.buyPrice = data.buyPrice;
    this.buyPriceStart = data.buyPriceStart;
    this.sellPrice = data.sellPrice;
    this.sellPriceStart = data.sellPriceStart;
  }
}

class OrderBookChangeEvent extends Event {
  public orderType: number;
  public price: number;
  public rounds: number;
  public roundsAdded: number;
  public roundsRemoved: number;

  constructor(type: string, data: { type: number; price: number; rounds: number, roundsAdded: number, roundsRemoved: number }) {
    super(type);
    this.orderType = data.type;
    this.price = data.price;
    this.rounds = data.rounds;
    this.roundsAdded = data.roundsAdded;
    this.roundsRemoved = data.roundsRemoved;
  }
}

class LastOrderBoughtEvent extends Event {
  public productId: string;
  public commodityId: string;
  public price: number;
  public rounds: number;
  public createdAt: string;

  constructor(type: string, data: { productId: string; commodityId: string; price: number; rounds: number; createdAt: string }) {
    super(type);
    this.productId = data.productId;
    this.commodityId = data.commodityId;
    this.price = data.price;
    this.rounds = data.rounds;
    this.createdAt = data.createdAt;
  }
}

// HELPER METHODS /////////////////////////////////

function extractOrderBook(data: any, orderBook: IOrderBook): IOrderBook {
  orderBook.buy.push(...data.buyers);
  orderBook.sell.push(...data.sellers);
  return orderBook;
}

function sortInPlaceByPrice(items: any[], shouldReverse: boolean = false) {
  items.splice(0, items.length, ...items.sort((a, b) => a.price - b.price));
  if (shouldReverse) items.reverse();
}

export interface IMarketPage extends IMarketSummary {
  marketType: string;
  buyPrice: number;
  sellPrice: number;
  isActive: boolean;
  orderBook: IOrderBook;
  activeInventory?: IInventoryHolding;
}

interface IOrderBook {
  buy: IOrderList;
  sell: IOrderList;
  [index: string]: IOrderList;
}

type IOrderList = IOrderListItem[];

interface IOrderListItem {
  price: number;
  rounds: number;
}

