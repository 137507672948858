
  import * as Vue from "vue";
  import OverlayLogin from "@contrarian/ui-shared/components/OverlayLogin.vue";
  import OverlayRegister from "@contrarian/ui-shared/components/OverlayRegister.vue";
  import OverlayDeposit from "@/components/OverlayDeposit.vue";
  import TradingOverlay from "@/components/TradingOverlay.vue";
  import Alerts from "@/components/Alerts.vue";

  export default Vue.defineComponent({
    components: {
      Alerts,
      OverlayLogin,
      OverlayRegister,
      OverlayDeposit,
      TradingOverlay,
    },
  });
