import { resolveComponent as _resolveComponent, createVNode as _createVNode, Suspense as _Suspense, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_OverlayLogin = _resolveComponent("OverlayLogin")!
  const _component_OverlayRegister = _resolveComponent("OverlayRegister")!
  const _component_OverlayDeposit = _resolveComponent("OverlayDeposit")!
  const _component_TradingOverlay = _resolveComponent("TradingOverlay")!
  const _component_Alerts = _resolveComponent("Alerts")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_openBlock(), _createBlock(_Suspense, null, {
      default: _withCtx(() => [
        _createVNode(_component_router_view)
      ]),
      _: 1
    })),
    _createVNode(_component_OverlayLogin),
    _createVNode(_component_OverlayRegister),
    _createVNode(_component_OverlayDeposit),
    (_openBlock(), _createBlock(_Suspense, null, {
      default: _withCtx(() => [
        _createVNode(_component_TradingOverlay)
      ]),
      _: 1
    })),
    _createVNode(_component_Alerts)
  ], 64))
}