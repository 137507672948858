
import * as Vue from "vue";
import { ChevronDownIcon } from "@heroicons/vue/20/solid";
import useEmitter from "@contrarian/ui-shared/composables/useEmitter";
import { useStore } from "vuex";
import { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot, Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
import { InformationCircleIcon } from "@heroicons/vue/20/solid";
import { directive as VueInputAutowidth } from "vue-input-autowidth";
import ProductMenu from "./ProductMenu.vue";

export default Vue.defineComponent({
  props: ['data'],
  emits: ['close'],
  directives: {
    autowidth: VueInputAutowidth,
  },
  components: {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    InformationCircleIcon,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    ChevronDownIcon,
    ProductMenu,
  },
  setup(props: any) {
    const emitter = useEmitter();
    const store = useStore();

    const commodityId = Vue.ref(props.data.commodityId);
    const productId = Vue.ref(props.data.productId);
    const roundsOwned = Vue.ref(props.data.roundsOwned);

    const price = props.data.price || 10;

    const skipConfirmation = Vue.ref(false);
    const milliCents = Vue.ref(price / 10);
    const rounds = Vue.ref(20);
    const roundsSold = Vue.ref(0);

    if (milliCents.value > 100) {
      milliCents.value = Number(milliCents.value.toFixed(0))
    }

    return {
      error: Vue.ref(''),
      emitter,
      store,
      milliCents,
      rounds,
      skipConfirmation,
      commodityId,
      productId,
      selectedProduct: Vue.ref(),
      price,
      roundsOwned,
      roundsSold,
    };
  },
  methods: {
    totalAmount() {
      const totalMillicents = Number(this.milliCents) * Number(this.rounds);
      const totalDollars = totalMillicents / 100;
      return totalDollars.toFixed(2);
    },
    onProductSelected(product: any) {
      this.selectedProduct = product;
      this.ensureRoundsRange();
    },
    async submitOrder() {
      if (this.rounds <= 0) return;
      const data = {
        price: this.milliCents * 10,
        rounds: Number(this.rounds),
        productId: this.productId || this.selectedProduct?.productId,
        commodityId: this.commodityId,
      };
      try {
        await this.store.dispatch('account/SubmitSellOrder', data);
        this.emitter.emit('addAlert', {
          id: `${data.productId}-${data.price}-${data.rounds}`,
          title: 'Offer Submitted',
          extra: 'You will be notified when processing is complete.',
        });
        this.roundsSold = Number(this.rounds);
        if (this.skipConfirmation) {
          this.closeOverlay();
        }
      } catch (error) {
        this.error = (error as Error).message;
      }
    },
    closeOverlay() {
      this.$emit('close');
    },
    decrCent() {
      this.milliCents = Math.max(1, Number(this.milliCents) - 1);
    },
    incrCent() {
      this.milliCents = Number(this.milliCents) + 1;
    },
    decrBox() {
      this.rounds = Number(this.rounds) - 20;
      if (this.rounds <= 20) {
        this.rounds = 20;
      }
    },
    incrBox() {
      this.rounds = Number(this.rounds) + 20;
      this.ensureRoundsRange();
    },
    ensureRoundsRange() {
      const roundsOwned = this.selectedProduct?.rounds || this.roundsOwned;
      if (this.rounds > roundsOwned) this.rounds = roundsOwned;
    }
  },
});
