import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "relative" }
const _hoisted_2 = { class: "absolute left-0 top-3.5 font-bold text-lg text-gray-800" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InlineSvg = _resolveComponent("InlineSvg")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.totalRounds), 1),
    _createVNode(_component_InlineSvg, {
      src: require(`@/assets/icons/truck.svg`),
      class: "inline mt-1 stroke-[0.5]",
      height: "55"
    }, null, 8, ["src"])
  ]))
}