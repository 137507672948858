<template>
<div class=" inline-block align-bottom w-full">

  <div class="pb-8">
    <div v-if="formError.message.length > 0" class="px-2 py-4 w-full rounded flex items-center">
      <div class="bg-red-200 rounded-md border border-red-600 mr-4 w-14 h-14 flex justify-center items-center">
        <ExclamationTriangleIcon class="h-8 w-8 text-red-600" aria-hidden="true" />
      </div>
      <div class="flex flex-col justify-center border-l pl-4">
        <h1 class="text-xl font-ammocorps text-red-600">Form Error</h1>
        <h1 class="text-lg text-slate-600">{{formError.message}}</h1>
      </div>
    </div>
    <div class="mt-2">
      <div class="w-12/12 border-b pb-4">
        <label class="font-thin text-slate-800">Full Name</label>
        <div class="">
          <input v-model="cardNameInput" autocomplete="cc-name" class=" appearance-none bg-transparent border border-slate-500 mt-2 w-full text-sm text-gray-700 py-3 rounded leading-tight focus:outline-none " type="text" placeholder="Full Name" aria-label="Full name" />
        </div>
      </div>
      <div class="w-full flex flex-col mt-4">
        <label class="font-thin text-slate-800">Card Number</label>
        <input type="text" autocomplete="cc-number" inputmode="numeric" pattern="[0-9\s]{13,19}" @keydown="styleCC" placeholder="Card Number" v-model="cardNumberInput" ref="card" class="border border-slate-500 rounded py-2 px-4 mt-2" />
      </div>
      <div class="space-x-4 flex mt-4">
        <div class="w-3/12 flex flex-col">
          <label class="font-thin text-slate-800">CVC</label>
          <input type="text" v-model="cvcInput" autocomplete="cc-csc" inputmode="numeric" placeholder="CVC" class="border border-slate-500 rounded py-2 px-4 mt-2" />
        </div>
        <div class="w-3/12">
          <label class="font-thin text-slate-800">Expiration</label>
          <input @keydown="styleExpDate" placeholder="MM/YY" v-model="expDateInput" autocomplete="cc-exp" class="border rounded border-slate-500 py-2 px-4 mt-2" />
        </div>
      </div>
      <div class="w-12/12 flex flex-col mt-4 border-b pb-4">
        <label class="font-thin text-slate-800">Zipcode</label>
        <input v-model="zipcodeInput" placeholder="Zipcode" autocomplete="postal-code" class="border border-slate-500 rounded py-2 px-4 mt-2" />
      </div>

    </div>
  </div>
  <button :disabled="loading" @click="saveCard" class="w-full border-indigo-500 hover:border-indigo-300 text-indigo-500 hover:text-indigo-300 border px-5 py-2 mb-2 rounded">
    {{ loading ? "Saving..." : "Save Card" }}
  </button>
</div>
</template>

<script>
import * as Vue from 'vue'

import useEmitter from "@contrarian/ui-shared/composables/useEmitter";
import {
  ExclamationTriangleIcon
} from '@heroicons/vue/20/solid'
import API from "@contrarian/ui-shared/lib/API";

export default Vue.defineComponent({
  components: {
    ExclamationTriangleIcon
  },
  props: ["memberId"],
  setup(props) {
    const emitter = useEmitter();
    const memberId = props.memberId
    const cardNameInput = Vue.ref('');
    const cardNumberInput = Vue.ref('');
    const expDateInput = Vue.ref('');
    const cvcInput = Vue.ref('');
    const zipcodeInput = Vue.ref('')
    const loading = Vue.ref(false)
    const card = Vue.ref();
    const formError = Vue.ref({
      severity: "",
      message: ""
    })

    return {
      emitter,
      loading,
      cardNameInput,
      cardNumberInput,
      expDateInput,
      cvcInput,
      zipcodeInput,
      card,
      memberId,
      formError
    }
  },

  methods: {
    styleCC($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $keyCode;
      if (keyCode != 8 && this.cardNumberInput.length > 0 && this.cardNumberInput.replace(/[^0-9.]/g, '').length % 4 == 0) {
        this.cardNumberInput = this.cardNumberInput + '-'
      }
    },
    styleExpDate($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $keyCode;
      if (keyCode != 8 && this.expDateInput.length > 0 && this.expDateInput.replace(/[^0-9.]/g, '').length == 2) {
        this.expDateInput = this.expDateInput + '/'
      }
    },
    onSuccessfulCardAdd() {
      this.emitter.emit("updateCashAvailable");
    },
    saveCard() {
      if (
        this.cardNameInput !== '' &&
        this.cardNumberInput.length >= 16 &&
        this.expDateInput !== '' &&
        this.zipcodeInput !== '' &&
        this.cvcInput !== ''
      ) {
        this.loading = true
        API.post('/fortis/saveCard', {
          firstName: this.cardNameInput.split(" ")[0],
          lastName: this.cardNameInput.split(" ")[1],
          cardNumber: this.cardNumberInput.replace(/[^0-9.]/g, ''),
          expDate: this.expDateInput.replace(/[^0-9.]/g, '')
        }).then(resposne => {
          if (resposne.statusCode) {
            this.loading = false
            this.formError = {
              severity: "error",
              message: resposne.result.detail
            }

          } else {
            this.onSuccessfulCardAdd();
            this.loading = false
          }

        }).catch(error => {
          this.loading = false
          this.formError = {
            severity: "error",
            message: "Unexpected Error Saving Card, Check card details."
          }
        })
      } else {
        this.formError = {
          severity: "error",
          message: "Missing Required Fields"
        }
      }
    },
  }
});
</script>
