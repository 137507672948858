import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createBlock as _createBlock, Transition as _Transition } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { class: "py-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ChevronDownIcon = _resolveComponent("ChevronDownIcon")!
  const _component_MenuButton = _resolveComponent("MenuButton")!
  const _component_MenuItem = _resolveComponent("MenuItem")!
  const _component_MenuItems = _resolveComponent("MenuItems")!
  const _component_Menu = _resolveComponent("Menu")!

  return (_openBlock(), _createBlock(_component_Menu, {
    as: "div",
    class: "relative w-full inline-block text-left"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_MenuButton, { class: "inline-flex justify-between w-full border border-gray-400 rounded shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none" }, {
        default: _withCtx(() => [
          (_ctx.product)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.product.productId), 1))
            : (_openBlock(), _createElementBlock("div", _hoisted_2, "Select Product")),
          _createVNode(_component_ChevronDownIcon, {
            class: "-mr-1 ml-2 h-5 w-5",
            "aria-hidden": "true"
          })
        ]),
        _: 1
      }),
      _createVNode(_Transition, {
        "enter-active-class": "transition ease-out duration-100",
        "enter-from-class": "transform opacity-0",
        "enter-to-class": "transform opacity-100",
        "leave-active-class": "transition ease-in duration-75",
        "leave-from-class": "transform opacity-100",
        "leave-to-class": "transform opacity-0"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_MenuItems, { class: "z-10 origin-top-left absolute left-0 w-full rounded-md rounded-t-none shadow-lg bg-white border border-gray-400 border-t-0 focus:outline-none" }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_3, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.activeInventories, (record, index) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: record.id,
                    class: ""
                  }, [
                    (record.rounds > 0 && _ctx.commodityId == record.commodityId )
                      ? (_openBlock(), _createBlock(_component_MenuItem, {
                          key: 0,
                          onClick: ($event: any) => (_ctx.selectProduct(record))
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", {
                              class: _normalizeClass([[index === 0 ? '' : 'border-t'], "cursor-pointer text-gray-700 block px-4 py-2 text-sm w-full hover:bg-gray-100"])
                            }, [
                              _createElementVNode("h1", null, _toDisplayString(record.productId) + " (" + _toDisplayString(record.rounds) + " rounds)", 1)
                            ], 2)
                          ]),
                          _: 2
                        }, 1032, ["onClick"]))
                      : _createCommentVNode("", true)
                  ]))
                }), 128))
              ])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}