import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "relative h-full bg-white p-3 text-gray-500 rounded-md text-left font-light text-sm" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FloatArrow = _resolveComponent("FloatArrow")!
  const _component_PopoverPanel = _resolveComponent("PopoverPanel")!
  const _component_Float = _resolveComponent("Float")!
  const _component_Popover = _resolveComponent("Popover")!

  return (_openBlock(), _createBlock(_component_Popover, null, {
    default: _withCtx(() => [
      _createVNode(_component_Float, {
        show: _ctx.show,
        placement: "bottom",
        offset: _ctx.offset || 15,
        arrow: ""
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", {
            onMouseenter: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.open && _ctx.open(...args))),
            onMouseleave: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.delayClose && _ctx.delayClose(...args)))
          }, [
            _renderSlot(_ctx.$slots, "default")
          ], 32),
          _createVNode(_component_PopoverPanel, {
            static: "",
            class: "w-[340px] bg-white border border-gray-200 rounded-md shadow-lg focus:outline-none pointer-events-none"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_FloatArrow, { class: "absolute bg-white w-5 h-5 rotate-45 border border-gray-200" }),
              _createElementVNode("div", _hoisted_1, [
                _renderSlot(_ctx.$slots, "tooltip")
              ])
            ]),
            _: 3
          })
        ]),
        _: 3
      }, 8, ["show", "offset"])
    ]),
    _: 3
  }))
}