import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-14273059"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "SellButton Component relative" }
const _hoisted_2 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InlineSvg = _resolveComponent("InlineSvg")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("button", {
      disabled: !_ctx.roundsOwned || !_ctx.tradingEngineStatus.allowTrading,
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.buttonClick && _ctx.buttonClick(...args))),
      class: _normalizeClass([_ctx.isOpen ? '' : 'text-opacity-90', "inline-block w-full text-center px-3 py-1.5 border border-gray-300 shadow-sm text-xs rounded text-gray-700 bg-white hover:border-gray-400 focus:outline-none"])
    }, [
      _renderSlot(_ctx.$slots, "default", {}, () => [
        _createVNode(_component_InlineSvg, {
          src: require(`@/assets/icons/sell.svg`),
          class: "inline stroke-black relative -top-[2px] mr-1",
          height: "14"
        }, null, 8, ["src"]),
        _createTextVNode(" Sell ")
      ], true)
    ], 10, _hoisted_2)
  ]))
}