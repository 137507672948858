
import * as Vue from 'vue';
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue';
import { ChevronDownIcon, CogIcon } from '@heroicons/vue/20/solid';
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import ShipmentIcon from './ShipmentIcon.vue';

export default Vue.defineComponent({
  components: {
    Popover,
    PopoverButton,
    PopoverPanel,
    ChevronDownIcon,
    CogIcon,
    ShipmentIcon,
  },
  setup() {
    const store = useStore();
    const router = useRouter();

    const hasShipment = Vue.computed(() => store.state.account.shippingItems.length > 0);
    const totalRounds = Vue.computed(() => store.state.account.shippingItems.reduce((acc: number, item: any) => acc + item.rounds, 0));

    return {
      store,
      router,
      hasShipment,
      totalRounds
    };
  },
  methods: {
    async logout() {
      await this.store.dispatch('session/Logout');
      window.location.replace('/');
    }
  }
});
