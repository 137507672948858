import { createStore } from "vuex";
import session from '@contrarian/ui-shared/store/session';
import account from './account';

const store = createStore({
  modules: {
    session,
    account,
  },
  state: {
  },
  mutations: {},
  actions: {},
});

store.dispatch('account/Load');

export default store;
