import { ActionContext } from "vuex";
import API from '@contrarian/ui-shared/lib/API';
import {emitter} from "@/main";
import {createPromisable} from "@/lib/Utils";

const defaultMember = {
  id: '',
  firstName: '------',
  lastName: '------',
  email: '----@-------.---',
  birthdate: null,
  cashAvailable: 0,
  roundsOwned: 0,
  pendingRoundsToBuy: 0,
  pendingRoundsToSell: 0,
  isFoundingTrader: null,
  isApprovedTrader: false,
  isApprovedWholesaler: false,
};

const isLoaded = createPromisable();

export default {
  namespaced: true,
  state: {
    certifiesIn: 0,
    memberId: defaultMember.id,
    member: { ...defaultMember },
    shippingItems: [],
    isLoaded: isLoaded.promise,
  },
  mutations: {
    eraseAll(state: any) {
      Object.keys(state).forEach(key => {
        if (key === 'member') {
          eraseMember(state);
        }
      });
      localStorage.removeItem('member');
    },

    eraseMember(state: any) {
      eraseMember(state)
    },

    updateMember(state: any, member: any) {
      for (const [key, value] of Object.entries(member)) {
        state.member[key] = value;
      }
      localStorage.setItem('member', JSON.stringify(state.member));
    },

    updateMemberId(state: any, memberId: string | null) {
      state.memberId = memberId;
    },

    replaceShippingItems(state: any, shippingItems: any) {
      state.shippingItems.splice(0, state.shippingItems.length, ...shippingItems);
      localStorage.setItem('shippingItems', JSON.stringify(state.shippingItems));
    },

    addShippingItem(state: any, newItem: any) {
      const index = state.shippingItems.findIndex((item: any) => item.productId === newItem.productId);
      if (index >= 0) {
        const existingItem = state.shippingItems[index];
        existingItem.rounds = Math.min(existingItem.roundsOwned, existingItem.rounds + newItem.rounds);
        existingItem.roundsOwned = newItem.roundsOwned;
        existingItem.boxSizes = newItem.boxSizes;
      } else {
        state.shippingItems.push(newItem);
      }
      localStorage.setItem('shippingItems', JSON.stringify(state.shippingItems));
    }
  },
  getters: {
    member(state: any) {
      return state.member;
    }
  },
  actions: {
    async SubmitBuyOrder({ state, commit }: any, data: any) {
      data.certifiesIn = state.certifiesIn;
      data.memberId = state.memberId;
      await API.post('/buyOrder', data);
      const pendingRoundsToBuy = state.member.pendingRoundsToBuy + data.rounds;
      commit('updateMember', { pendingRoundsToBuy });
      emitter.emit(`buyOrderSubmitted:${data.commodityId}`, data);
      if (data.productId) {
        emitter.emit(`buyOrderSubmitted:${data.productId}`, data);
      }
    },

    async SubmitSellOrder({ state, commit }: any, data: any) {
      data.certifiesAt = state.certifiesIn;
      data.memberId = state.memberId;
      await API.post('/sellOrder', data);
      const pendingRoundsToSell = state.member.pendingRoundsToSell + data.rounds;
      commit('updateMember', { pendingRoundsToSell });
      emitter.emit(`sellOrderSubmitted:${data.commodityId}`, data);
      if (data.productId) {
        emitter.emit(`sellOrderSubmitted:${data.productId}`, data);
      }
    },

    async LoadMember({ state, rootState, commit }: any) {
      const { memberId } = rootState.session;
      if (!state.memberId) {
        commit('updateMemberId', memberId);
      }
      if (!memberId) {
        commit('eraseMember');
        return;
      }

      const rawMember = localStorage.getItem('member');
      if (rawMember) {
        const cachedMember = JSON.parse(rawMember);
        if (cachedMember.id == memberId) {
          commit('updateMember', cachedMember);
        } else {
          commit('eraseMember');
        }
      }
      const member = await API.get(`/member/${memberId}`);
      if (member) {
        commit('updateMember', member);
      } else {
        commit('eraseMember');
      }
    },

    LoadShippingItems({ state, rootState, commit }: any) {
      const rawShippingItems = localStorage.getItem('shippingItems');
      if (rawShippingItems) {
        const shippingItems = JSON.parse(rawShippingItems);
        commit('replaceShippingItems', shippingItems);
      }
    },

    async Load({ state, rootState, commit, dispatch }: ActionContext<any, any>) {
      await Promise.all([
        dispatch('LoadShippingItems'),
        dispatch('LoadMember'),
      ]);
      isLoaded.resolve();
    }
  },
};

// HELPERS //////

function eraseMember(state: any) {
  for (const k of Object.keys(state.member)) {
    if (k in defaultMember) {
      // @ts-ignore
      state.member[k] = defaultMember[k];
    } else {
      delete state.member;
    }
  }
}
